import "./CSS/General.css";
import "./CSS/Hero.css";
import "./CSS/Header.css";
import "./CSS/OurCourses.css";
import "./CSS/Testimonials.css";
import "./CSS/CTA.css";
import "./CSS/Footer.css";
import "./CSS/Queries.css";
import Hero from "./Components/Hero";
import Header from "./Components/Header";
import OurCourses from "./Components/OurCourses";
import Testimonials from "./Components/Testimonials";
import CTA from "./Components/CTA";
import Footer from "./Components/Footer";
import { useEffect } from "react";
import { useState } from "react";

export default function App() {
  useEffect(() => {
    const sectionHeroEl = document.querySelector(".section-hero");

    const obs = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];

        if (!ent.isIntersecting) {
          document.querySelector(".App").classList.add("sticky");
        } else {
          document.querySelector(".App").classList.remove("sticky");
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );

    obs.observe(sectionHeroEl);

    // Clean up the observer on component unmount
    return () => {
      if (sectionHeroEl) obs.unobserve(sectionHeroEl);
    };
  }, []); // Empty dependency array ensures this runs once after the initial render

  return (
    <div className="App">
      <Header />
      <Hero />
      <OurCourses />
      <Testimonials />
      <CTA />
      <Footer />
    </div>
  );
}
