import logo from "../edupal-logo.png";

export default function footer() {
  return (
    <footer className="footer">
      <div className="container grid grid--footer">
        <div className="logo-col">
          <div className="logo-link">
            <a href="#home" className="footer-logo">
              <img className="logo" alt="Edupalnepal logo" src={logo} />
            </a>

            <ul className="social-links">
              <li>
                <a
                  className="footer-link"
                  href="https://www.facebook.com/edupalnepal"
                >
                  <ion-icon class="social-icon" name="logo-facebook"></ion-icon>
                </a>
              </li>

              <li>
                <a
                  className="footer-link"
                  href="https://www.youtube.com/@edupalnepal"
                >
                  <ion-icon
                    class="social-icon-yt"
                    name="logo-youtube"
                  ></ion-icon>
                </a>
              </li>
            </ul>
          </div>

          <p className="copyright">
            Copyright &copy; <span className="year">2024</span> by Edupalnepal
            PVT. LTD. All rights reserved.
          </p>
        </div>

        <div className="address-col">
          <p className="footer-heading">Contact us</p>
          <address className="contacts">
            <p className="address">
              Ratopool, Dhangadhi, Nepal - (Near Buspark)
            </p>
            <p>
              <a className="footer-link" href="tel:091-523368">
                091-523368
              </a>
              <br />

              <a className="footer-link" href="mailto:info@edupalnepal.com">
                info@edupalnepal.com
              </a>
            </p>
          </address>
        </div>

        <nav className="nav-col">
          <p className="footer-heading">Company</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="#hero">
                About Edupalnepal
              </a>
            </li>
            <li>
              <a className="footer-link" href="mailto:info@edupalnepal.com">
                For Business
              </a>
            </li>

            <li>
              <a className="footer-link" href="#cta">
                Careers
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
