import icon from "../edupal-logo.png";
import { useState } from "react";

export default function Header() {
  const [navMenu, setNavMenu] = useState(false);

  return (
    <header className={`header ${navMenu ? "nav-open" : ""} `}>
      <a href="#hero">
        <img className="logo" alt="Edupalnepal logo" src={icon} />
      </a>

      <nav className="main-nav ">
        <ul className="main-nav-list">
          <li>
            <a className="main-nav-link" href="#hero">
              Home
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="#our-courses">
              Our Courses
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="#testimonials">
              Testimonials
            </a>
          </li>
          <li>
            <a className="main-nav-link nav-cta" href="#cta">
              Contact Now
            </a>
          </li>
        </ul>
      </nav>

      <button className="btn-mobile-nav" onClick={() => setNavMenu(!navMenu)}>
        <ion-icon class="icon-mobile-nav" name="menu-outline"></ion-icon>

        <ion-icon class="icon-mobile-nav" name="close-outline"></ion-icon>
      </button>
    </header>
  );
}
