export default function ourCourses() {
  return (
    <section className="section-courses" id="our-courses">
      <div className="container center-text">
        <p className="subheading">Our Courses</p>
        <h2 className="heading-secondary">Top Courses By Top Instructors</h2>
      </div>

      <div className="container courses-grid grid grid--3-cols margin-bottom-md">
        <div className="courses">
          <img
            src="./Courses-imgs/Courses-loksewa-100.jpg"
            alt="courses of edupalnepal"
            className="course-img"
          />

          <div className="courses-content">
            <p className="course-title">Loksewa Tayari</p>
            <ul className="course-list">
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Na.su
              </li>
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Kharidaar
              </li>
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Sakha Adhikrit
              </li>
            </ul>
          </div>
        </div>
        <div className="courses">
          <img
            src="./Courses-imgs/Courses-TSC-100.jpg"
            alt="courses of edupalnepal"
            className="course-img"
          />

          <div className="courses-content">
            <p className="course-title">TSC Tayari</p>
            <ul className="course-list">
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Ma.Bi
              </li>
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Pra.Bi
              </li>
              <li className="course-item">
                <ion-icon
                  class="right-icon"
                  name="caret-forward-outline"
                ></ion-icon>{" "}
                Ni.Ma.Bi
              </li>
            </ul>
          </div>
        </div>

        <div className="banking-course">
          <div className="courses">
            <img
              src="./Courses-imgs/Courses-banking-100.jpg"
              alt="courses of edupalnepal"
              className="course-img"
            />

            <div className="courses-content ">
              <p className="course-title">Banking Tayari</p>
              <ul className="course-list">
                <li className="course-item">
                  <ion-icon
                    class="right-icon"
                    name="caret-forward-outline"
                  ></ion-icon>
                  ADBL
                </li>
                <li className="course-item">
                  <ion-icon
                    class="right-icon"
                    name="caret-forward-outline"
                  ></ion-icon>
                  NRB
                </li>
                <li className="course-item">
                  <ion-icon
                    class="right-icon"
                    name="caret-forward-outline"
                  ></ion-icon>
                  NBL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container more-courses">
        <a href="#our-courses" className="link">
          More courses &rarr;
        </a>
      </div>
    </section>
  );
}
