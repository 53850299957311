export default function cta() {
  return (
    <section className="section-cta" id="cta">
      <div className="container">
        <div className=" cta-container">
          <p className="subheading">Contact Now</p>
          <h2 className="heading-secondary">Contact us for Enquries</h2>
        </div>

        <div className="cta-grid">
          <div className="container call-container">
            <div className="call-now-container">
              <ion-icon name="call-outline" class="call-icon"></ion-icon>
              <h3 className="call-now">Call Now </h3>
            </div>
            <a className="call-number" href="tel:091-523368">
              091-523368
            </a>
          </div>

          <div className="container or-container">
            <h3>Or</h3>
          </div>

          <div className="cta">
            <h2 className="heading-submit ">Submit form</h2>
            <form className="cta-form" name="sign-up">
              <div>
                <label htmlFor="full-name">Full Name</label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="Your Name"
                  name="full-name"
                  required
                />
              </div>

              <div>
                <label htmlFor="phone">Phone Number</label>
                <input
                  id="phone"
                  type="tel"
                  placeholder="your number"
                  name="phone"
                  required
                />
              </div>

              <div>
                <label htmlFor="select-where">
                  In What course would you like to enroll ?
                </label>
                <select id="select-where" name="select-where" required>
                  <option value="">Please choose one option:</option>
                  <option value="friends">Nasu</option>
                  <option value="youtube">Kharidaar</option>
                  <option value="podcast">Sakha Adhikrit</option>
                  <option value="ad">TSC</option>
                  <option value="others">Banking</option>
                  <option value="others">Nepal Army</option>
                  <option value="others">Nepal Police</option>
                  <option value="others">Korean Language</option>
                  <option value="others">Japanese Language</option>
                  <option value="others">IELTS</option>
                </select>
              </div>

              <button className="btn btn--form">Submit Enquiry</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
